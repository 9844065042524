import { IonSpinner } from "@ionic/react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useGoogleMapContext } from "@src/app/context/googleMapContext";
import { LA_CENTER } from "@src/lib/deprecatedCode";
import { Location } from "@src/lib/interface";
import { memo, useEffect, useState } from "react";

import iconMarkerCurrentLocation from "../../assets/icons/google-map/markerCurrentLocation.svg";
import { googleMapContainerStyle } from "../openShifts/map/googleMapShifts.const";

interface AddressViewMapProps {
  location: Location | undefined;
}

function AddressViewMapComponent(props: AddressViewMapProps) {
  const { location } = props;
  const [mapLocation, setMapLocation] = useState(location ?? LA_CENTER);
  const { isMapLoaded } = useGoogleMapContext();

  useEffect(() => {
    if (!location) {
      return;
    }
    setTimeout(() => {
      setMapLocation(location);
    }, 500);
  }, [location]);

  if (!isMapLoaded) {
    return (
      <div style={{ textAlign: "center", marginTop: "10em" }}>
        <IonSpinner color="primary" />
      </div>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={googleMapContainerStyle}
      zoom={17}
      center={mapLocation}
      options={{
        disableDefaultUI: true,
      }}
    >
      {mapLocation && <Marker position={mapLocation} icon={iconMarkerCurrentLocation} />}
    </GoogleMap>
  );
}

export const AddressViewMap = memo(AddressViewMapComponent);
