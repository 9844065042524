import { InputChangeEventDetail } from "@ionic/core";
import { IonAlert, IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { enableGeoLocation, isGeoLocationEnabled } from "@src/app/common/location";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { convertToGoogleMapsLocation, getDeviceGeoLocation } from "@src/appV2/Location";
import { PlacesAutoComplete, getAddressByGeoCode } from "@src/lib/deprecatedCode";
import { Address } from "@src/lib/interface";
import { locate } from "ionicons/icons";
import { Fragment, useEffect, useState } from "react";

import { Place } from "./types";

interface LocationEditProps {
  searchLocation: string;
  onPlaceChange: (place: Place) => void;
  includeLine1Input: boolean;
  initAddress?: Address;
}

export function LocationEdit(props: LocationEditProps) {
  const { searchLocation, onPlaceChange, includeLine1Input, initAddress } = props;
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [geoLocationError, setGeoLocationError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState<boolean>(false);
  const [enablingServices, setEnablingServices] = useState<boolean>(false);
  const [place, setPlace] = useState<Place>(initAddress as Place);

  useEffect(() => {
    isGeoLocationEnabled()
      .then(setIsEnabled)
      .catch(() => setIsEnabled(false));
  }, []);

  const enableLocation = async () => {
    try {
      setEnablingServices(true);
      const { success } = await enableGeoLocation();
      setIsEnabled(success);
    } finally {
      setEnablingServices(false);
    }
  };

  const onCurrentLocation = async () => {
    try {
      setLoadingCurrentLocation(true);
      const { geoLocation } = await getDeviceGeoLocation();
      const googleMapsLocation = convertToGoogleMapsLocation(geoLocation);
      const address = await getAddressByGeoCode(googleMapsLocation);
      if (address && address.formatted) {
        onPlaceChange({ address, location: googleMapsLocation });
      } else {
        setGeoLocationError({
          isError: true,
          message: `Could not get current location, try entering your address`,
        });
      }
    } catch (error) {
      setGeoLocationError({
        isError: true,
        message: "Please allow Geo Location access",
      });
    } finally {
      setLoadingCurrentLocation(false);
    }
  };

  const handleCurrentLocationItemClick = () => {
    isEnabled ? onCurrentLocation() : enableLocation();
  };

  const handlePlaceChange = (changedPlace: Place) => {
    if (!changedPlace) {
      return;
    }
    const { address: { streetName, streetNumber, city, state, country, postalCode } = {} } =
      changedPlace;
    if (!(streetName && streetNumber && city && state && country && postalCode)) {
      logEvent(APP_V2_APP_EVENTS.GET_ADDRESS_INCOMPLETE_DATA_FAILURE, {
        changedPlace,
      });
      setGeoLocationError({
        isError: true,
        message:
          "That doesn’t look like a complete address. Make sure to enter the full address of your current residence.",
      });
      return;
    }
    const updatedPlace = {
      ...changedPlace,
      address: {
        ...changedPlace.address,
        line1: place?.address?.line1 ?? "",
      },
    };
    setPlace(updatedPlace);
    onPlaceChange(updatedPlace);
  };

  const onAddressLineOneChange = ({ detail }: CustomEvent<InputChangeEventDetail>) => {
    setPlace({
      ...place,
      address: {
        ...place?.address,
        line1: detail.value as string,
      },
    });
  };

  const handleAddressLine1Blur = () => {
    onPlaceChange(place);
  };

  return (
    <Fragment>
      <IonAlert
        mode="ios"
        isOpen={geoLocationError.isError}
        backdropDismiss={false}
        header="Please input a full address"
        message={geoLocationError.message}
        buttons={[
          {
            text: "OK",
            handler: () => setGeoLocationError({ isError: false, message: "" }),
          },
        ]}
      />
      <IonItem className="form-item-wrapper">
        <PlacesAutoComplete defaultValue={searchLocation} onChange={handlePlaceChange} />
      </IonItem>
      {includeLine1Input && (
        <IonInput
          // disable if the user has already picked a subpremise using autocomplete
          disabled={place?.address?.subpremise ? true : false}
          clearInput={true}
          placeholder="Apartment/Floor/Building (if applicable)"
          value={place?.address?.subpremise || place?.address?.line1}
          onIonChange={onAddressLineOneChange}
          onBlur={handleAddressLine1Blur}
        />
      )}
      <IonItem
        lines="none"
        button
        detail={false}
        onClick={handleCurrentLocationItemClick}
        className=" form-item-wrapper"
      >
        <IonIcon icon={locate} mode="md" slot="start" color="primary" className="form-icon" />
        <IonLabel>
          <h4>Use your current location</h4>
          <p>
            {(() => {
              if (loadingCurrentLocation || enablingServices) {
                return "Loading...";
              } else {
                if (isEnabled) {
                  return "Locate using GPS";
                } else {
                  return "Enable Location Services";
                }
              }
            })()}
          </p>
        </IonLabel>
      </IonItem>
    </Fragment>
  );
}
