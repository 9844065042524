import { Text } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Box, Button, Card, CardContent, Divider, Stack } from "@mui/material";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { formatAddressWithLine1 } from "@src/lib/deprecatedCode/src/lib/helpers/addressFormatter";
import { Address as AddressBody, Location } from "@src/lib/interface";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { AddressViewMap } from "./addressViewMap";
import { USER_EVENTS } from "../../constants/userEvents";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { setAddressEditDetails } from "../store/accountSettings";

export function AddressView() {
  const [address, setAddress] = useState<AddressBody>({});
  const [location, setLocation] = useState<Location>();

  const worker = useDefinedWorker();
  const dispatch = useDispatch();
  const history = useHistory();

  useLogEffect(USER_EVENTS.VIEWED_ADDRESS);

  const onEdit = () => {
    const formattedAddress = address.formatted ?? "";
    if (formattedAddress && location) {
      dispatch(
        setAddressEditDetails({
          searchLocation: formattedAddress,
          address,
          location,
          addressLine1: address.line1 ?? "",
        })
      );
    } else {
      dispatch(setAddressEditDetails({}));
    }
    history.push(`/home/account/searchAddressLocation`);
  };

  useEffect(() => {
    const { geoLocation, address } = worker;
    const lat = geoLocation?.coordinates?.[1];
    const lng = geoLocation?.coordinates?.[0];
    if (address && !isEmpty(address) && lat && lng) {
      setAddress(address);
      setLocation({ lat, lng });
    } else {
      setAddress({});
      setLocation(undefined);
      history.push(`/home/account/searchAddressLocation`);
    }
  }, [worker, history]);

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Address"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
          />
        }
        containerVariant="without-margin"
      >
        <Stack height="100%">
          <Box flex={1}>
            <AddressViewMap location={location} />
          </Box>
          <Box>
            <Card elevation={4} sx={{ margin: 1 }}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Text bold>Address</Text>
                    <Button size="small" variant="outlined" onClick={onEdit}>
                      Edit
                    </Button>
                  </Stack>
                  <Divider />
                  <Text>{formatAddressWithLine1(address) || "No Saved Address"}</Text>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
