import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { AddressBody, AddressResponse } from "@src/lib/interface";
import request from "superagent";
import timezoneLookup from "tz-lookup";

import { getAuthHeader } from "../superagent";

const updateAddress = async (address: AddressBody): Promise<AddressResponse | undefined> => {
  try {
    const tmz = timezoneLookup(address.location?.lat, address.location?.lng);
    const { body, status } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/address`)
      .set(await getAuthHeader())
      .send({ ...address, tmz });
    return { ...body, status };
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { updateAddress };
