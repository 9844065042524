import { Text } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { formatAddressWithLine1 } from "@src/lib/deprecatedCode/src/lib/helpers/addressFormatter";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { LocationEdit } from "./locationEdit";
import { Place } from "./types";
import { setAddressEditDetails } from "../store/accountSettings";
import { Store } from "../store/store.model";

interface SearchLocationProps {
  editPath?: string;
}

export function SearchLocationEdit(props: SearchLocationProps) {
  const { editPath } = props;
  const { addressEditDetails } = useSelector((state: Store) => state.accountSettingsStore);
  const dispatch = useDispatch();
  const history = useHistory();

  // if the address has a line1, then we need to set the searchLocation string to include that
  // so it's correctly displayed in the UI, otherwise it looks like data is missing
  useEffect(
    () => {
      if (addressEditDetails?.address?.line1) {
        dispatch(
          setAddressEditDetails({
            ...addressEditDetails,
            searchLocation: formatAddressWithLine1(addressEditDetails.address),
          })
        );
      }
    },
    /**
     * This effect has a circular dependency. `addressEditDetails` and `setAddressEditDetails`
     * Until we rework this to not use redux, and eliminate the `useEffect`, we'll have to ignore
     * this rule of hooks.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const onPlaceChange = (place: Place) => {
    if (isEmpty(place.location) || isEmpty(place.address)) {
      return;
    }

    dispatch(
      setAddressEditDetails({
        searchLocation: place.address.formatted,
        address: place.address,
        location: place.location,
        addressLine1: place.address.line1 ?? "",
      })
    );
    history.push(editPath || DeprecatedGlobalAppV1Paths.ADDRESS_EDIT);
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Address"
            leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.ADDRESS} />}
          />
        }
      >
        <Stack spacing={1}>
          <Text>
            Please enter your home address (including Apartment or Unit #) so we can find facility
            matches in your area.
          </Text>{" "}
          <LocationEdit
            searchLocation={addressEditDetails?.searchLocation ?? ""}
            onPlaceChange={onPlaceChange}
            includeLine1Input={false}
          />
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
