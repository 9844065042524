import "./style.scss";
import { RangeChangeEventDetail, RangeValue } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRange,
  IonText,
} from "@ionic/react";
import { Box, Button } from "@mui/material";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { PlacesAutoComplete } from "@src/lib/deprecatedCode";
import { Location } from "@src/lib/interface";
import { home, medkit } from "ionicons/icons";
import { isEmpty } from "lodash";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { updateAddress, updateDistancePreference } from "./api";
import { AddressAndDistanceProps, Place } from "./model";
import { DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES } from "../../constants";
import { setAddressEditDetails } from "../store/accountSettings";
import { Store } from "../store/store.model";

export const AddressAndDistance: FC<AddressAndDistanceProps> = ({
  isOpen,
  closeModal,
  updateCalender,
}) => {
  const history = useHistory();
  const worker = useDefinedWorker();
  const updateWorkerCache = useUpdateWorkerCache();

  const [distance, setDistance] = useState<RangeValue>(
    worker?.preference?.distance ?? DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES
  );
  const { addressEditDetails } = useSelector((state: Store) => state.accountSettingsStore);
  const dispatch = useDispatch();
  const onPlaceChange = (place: Place) => {
    if (isEmpty(place.location) || isEmpty(place.address)) {
      return;
    }

    dispatch(
      setAddressEditDetails({
        searchLocation: place.address.formatted,
        address: place.address,
        location: place.location,
        addressLine1: place.address.line1 ?? "",
      })
    );
    // history.push(editPath || `/home/account/editAddress`)
  };
  const onChange = (event: CustomEvent<RangeChangeEventDetail>) => {
    const { value } = event.detail;
    setDistance(value);
  };

  const onSave = async () => {
    await updateAddress({
      address: {
        ...addressEditDetails?.address,
        line1: addressEditDetails?.addressLine1 ?? "",
      },
      location: addressEditDetails?.location as Location,
    });

    const updatedWorker = await updateDistancePreference(distance as number);

    updateWorkerCache(updatedWorker);

    history.push("/home/openShifts");
    closeModal?.();
  };
  const closeEditDetail = () => {
    if (isEmpty(addressEditDetails)) {
      closeModal?.();
    } else {
      onSave();
    }
  };

  return (
    <IonModal isOpen={isOpen as boolean}>
      <>
        <Box textAlign="right">
          <Button variant="text" onClick={closeEditDetail} size="small">
            {isEmpty(addressEditDetails) ? "Close" : "Done"}
          </Button>
        </Box>
        <IonContent className="modal-container">
          <div className="input-area">
            <IonText color="dark" className="modal-title">
              <h1>My Address</h1>
            </IonText>
            <IonText color="dark" className="modal-subtitle">
              <p>
                {`Please enter your exact physical address so that we can best show you
                shifts near you`}
              </p>
            </IonText>
            <div className="address-input-container">
              {/*<IonInput className="address-input" placeholder="Your address"/>*/}
              <IonItem>
                <PlacesAutoComplete
                  defaultValue={addressEditDetails?.searchLocation ?? ""}
                  onChange={onPlaceChange}
                />
              </IonItem>
            </div>
          </div>
        </IonContent>
        {!isEmpty(addressEditDetails) && (
          <IonContent className="modal-container">
            <div className="distance-slider-container">
              <IonText color="dark" className="modal-subtitle">
                <h3>How far are you willing to travel to work</h3>
              </IonText>
              <IonRange
                min={1}
                max={DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES}
                pin
                value={distance}
                debounce={100}
                onIonChange={onChange}
              >
                <IonIcon slot="start" icon={home} />
                <IonIcon slot="end" icon={medkit} />
              </IonRange>
              <IonItem>
                <IonLabel className="ion-text-center">
                  <p>{distance} miles</p>
                </IonLabel>
              </IonItem>
            </div>
            <div className="address-input-container">
              <IonButton expand="full" onClick={onSave}>
                Done
              </IonButton>
            </div>
          </IonContent>
        )}
      </>
    </IonModal>
  );
};
