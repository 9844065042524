import { ReactElement, useEffect } from "react";
import { render } from "react-dom";

export const useMapControl = (
  map: google.maps.Map | null,
  controlPosition: google.maps.ControlPosition | null,
  children: ReactElement
) => {
  useEffect(() => {
    if (map && controlPosition) {
      render(
        <div ref={(el) => map.controls[controlPosition].push(el as HTMLElement)}>{children}</div>,
        document.createElement("div")
      );
    }
    return () => {
      if (map && controlPosition) {
        map.controls[controlPosition].clear();
      }
    };
  }, [map, controlPosition, children]);
};
